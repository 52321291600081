<!--
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 16:14:18
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-01 16:14:18
 * @FilePath: /hblg-site-dev/src/components/player.vue
-->
<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	.tips {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
}
</style>

<template>
	<div class="container">
		<div class="tips" v-show="tips">{{ tips }}</div>
		<video
			v-show="!tips"
			ref="videoElement"
			controls
			autoplay
			width="100%"
			height="100%"
		></video>
	</div>
</template>

<script>
import hlsjs from 'hls.js';
export default {
	props: {
		url: String,
	},
	data() {
		return {
			hlsjs: null,
			tips: null,
		};
	},
	mounted() {
		if (!this.url) {
			this.tips = '未检测到媒体编号';
		} else {
			this.hlsjs = null;
			this.tips = null;
			let video = this.$refs.videoElement;
			if (hlsjs.isSupported()) {
				this.isSupported = true;
				this.hlsjs = new hlsjs();
				this.hlsjs.loadSource(this.url);
				this.hlsjs.attachMedia(video);
				this.hlsjs.on(hlsjs.Events.ERROR, (event, { err }) => {
					if (err) {
						this.tips = '加载失败：' + err;
					}
				});
			} else {
				this.tips = '不支持的媒体格式';
				return;
			}
		}
	},
	beforeDestroy() {
		if (this.hlsjs) {
			this.$refs.videoElement.pause();
			this.hlsjs.destroy();
			this.hlsjs = null;
		}
	},
};
</script>
