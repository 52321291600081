<!--
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 15:53:58
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-06 13:15:34
 * @FilePath: /hblg-site-dev/src/views/Home.vue
-->
<style lang="less" scoped>
.ant-layout {
  width: 100%;
  height: 100%;
  .ant-layout-header {
    .logo {
      width: 150px;
      height: 31px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 24px 16px 0;
      float: left;
      line-height: 1em;
      color: #fff;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
  .ant-layout-content {
    width: 100%;
    height: calc(100% - 69px - 64px);
    .content {
      width: 100%;
      height: calc(100% - 50px);
      overflow: hidden auto;
      background: #fff;
      padding: 20px;
    }
  }
  .ant-layout-footer {
  }
}
</style>

<template>
  <a-layout>
    <a-layout-header>
      <div class="logo">湖北隆感科技</div>
      <a-menu theme="dark" mode="horizontal" :default-selected-keys="[menusSelectedKey]" :style="{ lineHeight: '64px' }" @click="handleChange">
        <a-menu-item v-for="(item, key) in menus" :key="key">
          {{ item }}
        </a-menu-item>
      </a-menu>
    </a-layout-header>
    <a-layout-content style="padding: 0 50px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>{{ menus[menusSelectedKey] }}</a-breadcrumb-item>
        <a-breadcrumb-item>详情</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="content">
        <router-view />
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      App Center ©2021 Created by Hblg.ltd
    </a-layout-footer>
  </a-layout>
</template>

<script>
import LivePlayer from '@/components/player';
export default {
  components: {
    LivePlayer,
  },
  data() {
    return {
      menus: {
        xiaofang: '智慧消防',
        anfang: '智慧安防',
        yuanqu: '智慧园区',
        xiaoyuan: '智慧校园',
      },
    };
  },
  computed: {
    menusSelectedKey() {
      return this.$route.path.split('/')[1];
    },
  },
  methods: {
    handleChange({ key }) {
      this.$router.push('/' + key);
    },
  },
};
</script>
